<template>
  <div style="height:100vh" >
  <header >   
        <h2>Cadastro do Promotor</h2>
        <br/>
        <hr>
  </header>

            <br/>

<div data-app>

    <v-dialog
      v-model="resposta_erro"
      width="300"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Mensagem
        </v-card-title>

        <v-card-text>
        {{msg_dialog}}

       
        <br/>
              <v-btn  style="width:160px;background-color:#4caf50;" color=success class="white--text"
                @click="ok_val" rounded   v-if="acesso_login == true"
                :loading="loading" :disabled="loading">
                <b>Acessar</b>
              </v-btn>

              <br/>

               <v-btn  style="width:160px;background-color:#4caf50;" color=secondary class="white--text"
                @click="resposta_erro = false" rounded  >
                <b>Ok</b>
              </v-btn>

          


        </v-card-text>

      </v-card>
    </v-dialog>


    


    <v-dialog
      v-model="dialog_sms"
      persistent
      max-width="450px" >
     
      <v-card>
        <v-card-title>
          <span class="text-h5">Confirmação de Cadastro</span> <br/>
        </v-card-title><br/><br/>
                  Enviamos um SMS com o código de ativação para o telefone cadastrado 

        <v-card-text>
        <br/>
          <v-container>
            <v-row>
               <v-col cols="12" md="12">
                  <v-text-field 
                    label="Código De Ativação"  v-mask="'#######'"  Required :rules="[v => !!v || 'Campo Obrigatório']" style="margin-left:4px;"
                    filled v-model="promotor.sms"
                    prepend-inner-icon="mdi-cellphone-basic"
                  ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1"  text @click="fechar">
            Fechar
          </v-btn>
          <v-btn  style="background-color:#4caf50;" color=success class="white--text"
                @click="validarPromotor">
                <b>Confirmar</b>
                <v-icon right dark>mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

   


</div>  



  
<v-form ref="form">


<v-container class="grey lighten-5" >
    <v-row >
      <v-col cols="12" md="6">
            <v-text-field 
              label="Nome *" Required :rules="[v => !!v || 'Campo Obrigatório']" style="margin-left:4px;"
              filled v-model="promotor.nome" x-large
              prepend-inner-icon="mdi-account-box"
            ></v-text-field>
      </v-col>


       <v-col cols="12" md="6">
          <v-text-field 
              label="E-mail *" Required :rules="[v => !!v || 'Campo Obrigatório']" style="margin-left:4px;"
              filled v-model="promotor.email"
              prepend-inner-icon="mdi-email"
            ></v-text-field>
      </v-col>


       <v-col cols="12" md="6">
           <v-text-field 
              label="Senha * " type="password"  Required :rules="[v => !!v || 'Campo Obrigatório']" style="margin-left:4px;"
              filled v-model="promotor.senha"
              prepend-inner-icon="mdi-account-key"
            ></v-text-field>
      </v-col>


        <v-col cols="12" md="6">
            <v-text-field 
              label="Celular * "  v-mask="'(##) #####-####'"  Required :rules="[v => !!v || 'Campo Obrigatório']" style="margin-left:4px;"
              filled v-model="promotor.celular"
              prepend-inner-icon="mdi-cellphone-basic"
            ></v-text-field>
        </v-col>


        <v-col cols="12" md="12">
          <v-checkbox style="font-size:22px;color:red;" 
          v-model="promotor.funcionario_grupo"
                label="Funcionário do Grupo Parque das Flores?"
                color="blue"
                hide-details>
            </v-checkbox>
         </v-col>

         <v-col cols="12" md="6">
              <v-select  v-model="promotor.empresa"  :items="empresas_grupo" v-if="promotor.funcionario_grupo == true" filled  label="Empresa" prepend-inner-icon="mdi-bookmark"></v-select>
               <v-text-field label="Empresa" style="margin-left:4px;" filled v-if="promotor.funcionario_grupo == false" v-model="promotor.empresa" prepend-inner-icon="mdi-bookmark"
            ></v-text-field>
        </v-col>

         <v-col cols="12" md="6">
               <v-text-field label="Departamento" style="margin-left:4px;" filled  v-model="promotor.departamento" prepend-inner-icon="mdi-bookmark"
            ></v-text-field>
        </v-col>


* Campos obrigatórios


        <br/>
         <v-spacer></v-spacer>       

         <v-spacer></v-spacer>       
        <br/>
        <v-col cols="12" md="12">

            
               <v-btn   x-large rounded  style="width:200px;background-color:rgb(0 0 0 / 87%);margin-top:10px"
                  class="white--text"
                  to="login">
                  
                  <b>Login</b>
                  <v-icon right dark>mdi-key</v-icon>
                </v-btn>


              <v-btn  style="width:200px;background-color:rgb(0 0 0 / 87%);margin-top:10px" class="white--text"
                 @click="clear" x-large rounded >
                <b>Limpar</b>
                <v-icon right dark>mdi-broom</v-icon>
              </v-btn>

            
             <v-btn  style="width:200px;background-color:#rgb(0 0 0 / 87%);margin-top:10px"  class="white--text"
                @click="dialog_sms = true" x-large rounded  >
                <b>Ativar Código</b>
                <v-icon right dark>mdi-brightness-5</v-icon>
              </v-btn>


              <v-btn  style="width:280px;background-color:#4caf50;margin-top:10px" class="white--text"
                @click="confirmarPreCadastro" x-large rounded 
                :loading="this.loading" >
                <b>Confirmar</b>
                <v-icon right dark>mdi-content-save</v-icon>
              </v-btn>

             

        </v-col>
    </v-row>
  </v-container>
</v-form>
   
  <div class="img_central" >
        </div>  
        
  <footer>
      <div class="container">
       
        <div class="social">
          <div class="text">

            <p>Rua José Alencar, nº 121, Maceió</p>
            <p>{{ new Date().getFullYear() }} -<strong> Previda</strong></p>
          </div>
        </div>
    
      </div>
    </footer>

  </div>  
</template>

<script>
import ValidarCPF from '../../services/cpf';
  import Promotor from '../../services/promotor'


  export default {
    name: 'Promotor',
   

    data () {
      return {
         components: {
          DashboardCoreView: () => import('../dashboard/components/core/View'),
          },
        dialog:false,
        resposta_erro:false,
        msg_dialog:'',
        empresas_grupo: [
        'Previda',
        'Memorial Parque Maceió',
        'Parque das Flores',
        'Campo Santo Parque do Agreste',
        'Cemitério Previda',
        ], 


        promotor:{
            nome: '',
            cpf:'',
            email:'',
            senha:'',
            telefone:'',
            celular:'',
            endereco:'',
            cidade:'',
            uf:'',
            funcionario_grupo: true,
            empresa: '',
            sms:'',
            keyp:'',
        
        },
        dialog_sms: false,
        loading:false,
        acesso_login:false,
        message: '',
      }
    },

   
    methods: {

        clear () {
          this.$refs.form.reset();
        },
        valida_cpf(){

                if(!ValidarCPF(this.contrato.cpf)){
                    this.msg = 'CPF ' + this.contrato.cpf+ ' informado não é valido!';
                    this.contrato.cpf = '';
                    this.dialog_msg = true;
                }
       },

      ok_val(){
        this.$router.push('login');
               // router.push('/login')
      },
      fechar(){
        this.dialog_sms = false;
        this.clear;

      },
      validarPromotor(){
              this.loading = true;

            Promotor.verificarcodigo(this.promotor.sms).then(response => {
              
              if(response.data.codigo == 0){
                this.resposta_erro = true;
                this.acesso_login = true;
                this.msg_dialog = response.data.mensagem;
                this.$refs.form.reset();
                this.dialog_sms = false;
                this.loading = false;

              }else{
                  this.resposta_erro = true;
                  this.msg_dialog = response.data.mensagem;
                  this.loading = false;

              }

              }).catch(e => {
              if(e.status === 401){
                this.loading = false;
                  
              }
             }).finally(() => {
               this.loading = false
              })
      },


    
      confirmarPreCadastro(){

        this.loading = true;
        if(this.$refs.form.validate()){

            Promotor.cadastropromotor(this.promotor).then(response => {
                if(response.data.codigo == 0){
                    this.dialog_sms = true;
                }else{
                    this.resposta_erro = true;
                    this.msg_dialog = response.data.mensagem;
                }

              }).catch(e => {
              if(e.status === 401){
                 this.loading = false
              }
             }).finally(() => {
                 this.loading = false
              })
        
        }else{
           this.loading = false;
           alert('Preencha todos os campos')
        }
      },



    },
  }
</script>


<style scoped> 

*{
   padding:0;
  text-decoration:none;
  text-align:center;
  box-sizing:border-box;
  font-family:"roboto";

  }

 

  .box {
  width: 300px;
  height: 300px;
  background: #fff;
  }



  logo{
       flex-direction:row;
    align-items:center;
  }

  hr{
  border: 2px solid #565d68;
  border-radius: 5px;
  width:50%;
  margin-left:25%;

  }

  @media screen and (max-width: 1000px) {
    .logomarca{
      max-width:200px
    }
      
  }  
    .logomarca{
      width:240px;
    }

  img{
    line-height: normal;
    max-width:200px;    
    }

    .img_central{

        justify-content:center;

    }
  html{
    font-size:22.5%;
    
    }


 
  @media (max-width: 1300px){
    
    html{
      font-size:18%
      }
      .container{
        width:100%;
        padding:1.5rem;
        color:#464646; 
        margin:1 auto

    }
  }
  @media (max-width: 90px){
     
    html{
      font-size:1.5%
      }
  }@media (max-width: 20px){
    html{
      font-size:28%
      }
  }
  html,body{
    margin: 0px;
    overflow-x:hidden
    }
  button{
    background-color:transparent;
    cursor:pointer
  }

  header{
    padding:2rem 0;
    text-align:center;
    font-size:1rem;
    font-family:"roboto";
    justify-content:center;
  }
  header .container{
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
  }
  header .container .text h1{
    text-transform:uppercase;
    font-size:2.8rem;
    margin-bottom:0.1rem;
    text-align:center;
        letter-spacing:0.2rem;
    font-family:"roboto";
    color:#565d68;

  }
  header .container .text h3{
    font-size:1.3rem;
    color: white;
    text-align:center;
    background:#9fc1d7;
    letter-spacing:0.1rem;
    font-family:"roboto";
    color:#565d68;

  }
  @media (max-width:485px){ .figlado{ visibility: hidden; } }
  @media (max-width: 100px){
    header .container{
      flex-direction:column;}
  }
  @media (max-width: 480px){

   
    header .container .text h1{
      font-size:1.2rem
    }
    header .container .text h3{
      font-size:1rem;
      }
    }

    footer{
      position:relative;

      padding-bottom:0
    }
    footer .container{
      display:flex;
      flex-direction:row;
      align-items:center;
      justify-content:center;
      padding-bottom:0;
      height:12rem
    }
    footer .container .img-left{
      position:absolute;
      bottom:0;
      left:0
    }
    footer .container .img-right{
      position:absolute;
      bottom:0;
      right:0
    }
    footer .container .social{
      width:50%
    }
    footer .container .social .icones{
      flex-direction:row;
      max-width:180px;
      align-items:center;
      background-color:blue;
      justify-content:center;
      width:100%
    }
    footer .container .social .icones .icone{
      background:#0a5587;
      background-color:blue;
            justify-content:center;
      max-width:180px;
      width:74px;
      height:74px;
      padding:1.2rem;
      border-radius:25%
      }
      footer .container .social .text{
        margin-top:0.1rem
      }
      footer .container .social .text p{
        text-align:center;
        font-size:1rem;
        font-family:'roboto';
        color:#464646;
        letter-spacing:0.2rem
      }
      footer .container .social .text p:last-child{
        margin-top:1rem
      }
      @media (max-width: 1160px){
          
        footer .container{
          height:55rem
        }
        footer .container .img-left,footer .container .img-right{
          max-width:25%
          
          }
        footer .container .social .text p{
          font-size:1rem
        }
      }
      
      @media (max-width: 480px){
        footer .container{
          height:12rem
        }
        footer .container .social{
          width:100%
        }
      }
     
     

 



</style>

